import Testmonial from './Testimonial';
import About from './About';
// import Header from './Header';
import Banner from './Banner';
// import Places from './Places'; 
import Blog from './HomeBlog';
import Upcomingtrip from './Upcomingtrip';
// import HomeDestination from './HomeDestination';
import VideoHome from './VideoHome';
import { Link } from 'react-router-dom';
// import YchoseUs from './YchoseUs';

const Home = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
  };

  return (
    <>
  {/* <div className="custom-cursor__cursor" />
  <div className="custom-cursor__cursor-two" /> */}
  
  <div className="page-wrapper">
    {/* <Header/> */}
    {/* /.main-header */}
    {/* main-slider-start */}
    <Banner/>
    {/* main-slider-end */}
    <Upcomingtrip/>
    {/* /.tour-type */}
  
  {/* About Start */}
    <About/>
  {/* About End */}
  
  {/* Tour Listing two Start */}
  {/* <Places/> */}
  {/* Tour Listing two End */}

  {/* CTA Two End */}
  <VideoHome/>

  <section className="tour-type pad">
    <div
      className="tour-type__bg"
      style={{ backgroundImage: "url(assets/images/shapes/tour-type-bg.png)" }}
    />
    {/* /.tour-type__bg */}
    <div className="container">
      <div className="sec-title text-center">
        <p className="sec-title__tagline">Tour Categories</p>
        <h2 className="sec-title__title">Choose Tour Types</h2>
      </div>
      <div className="row">
        <div className="col-xl-3 wow fadeInUp" data-wow-delay="100ms">
        <Link to={`passion/${'beach-chill-698'}`} onClick={scrollToTop}>
          <div className="tour-type__box">
            <div className="tour-type__box__icon">
              <span className="icon-windsurfing-2" />
            </div>
            <h3 className="tour-type__box__title">Beach Chill</h3>
          </div>
          </Link>
        </div>
        <div className="col-xl-3 wow fadeInUp" data-wow-delay="150ms">
        <Link to={`passion/${'water-sports-705'}`} onClick={scrollToTop}>
          <div className="tour-type__box">
            <div className="tour-type__box__icon">
              <span className="icon-paragliding-5" />
            </div>
            <h3 className="tour-type__box__title">Water Sport</h3>
          </div>
          </Link>
        </div>
        <div className="col-xl-3 wow fadeInUp" data-wow-delay="200ms">
        <Link to={`passion/${'little-wanderer-704'}`} onClick={scrollToTop}>
          <div className="tour-type__box">
            <div className="tour-type__box__icon">
              <span className="icon-hiking-4" />
            </div>
            <h3 className="tour-type__box__title">Little Wanderer</h3>
          </div>
        </Link>
        </div>
        <div className="col-xl-3 wow fadeInUp" data-wow-delay="250ms">
        <Link to={`passion/${'birding-699'}`} onClick={scrollToTop}>
          <div className="tour-type__box">
            <div className="tour-type__box__icon">
              {/* <span className="icon-hang-gliding-3" /> */}
              <span className="icon-wildlife" />
            </div>
            <h3 className="tour-type__box__title">Birding</h3>
          </div>
        </Link>
        </div>
        <div className="col-xl-3 wow fadeInUp" data-wow-delay="300ms">
        <Link to={`passion/${'wildlife-16'}`} onClick={scrollToTop}>
          <div className="tour-type__box">
            <div className="tour-type__box__icon">
              <span className="icon-deer-silhouette-1" />
            </div>
            <h3 className="tour-type__box__title">Wildlife</h3>
          </div>
          </Link>
        </div>
      </div>
    </div> 
  </section>
  {/* /.tour-type */}
  {/* /.client-carousel */}

  {/* Destination Start */}
  {/* <HomeDestination/> */}
  {/* Destination End */}
  
  {/* Counter Three Start */}
  <section className="counter-three">
    <div
      className="counter-three__bg"
      style={{ backgroundImage: "url(assets/images/shapes/counter-bg-3.png)" }}
    />
    <div className="counter-three__shape-top" />
    <div className="container">
      <div className="row">
        <div className="col-xl-6 col-md-12 wow fadeInUp" data-wow-delay="100ms">
          <div className="sec-title text-left">
            <p className="sec-title__tagline">Are you ready to travel</p>
            {/* /.sec-title__tagline */}
            <h2 className="sec-title__title">
            Explore Our Tours &amp; 
              <br />Start Your Adventure Today
            </h2>
            {/* /.sec-title__title */}
          </div>
          {/* /.sec-title */}
          {/* /.sec-title */}
          <p className="counter-three__text">
          Embark on an adventure with EarthyHues, offering handpicked tours blending excitement, relaxation, and cultural immersion for unforgettable memories.
          </p>
        </div>
        <div className="col-xl-2 col-md-4 wow fadeInUp" data-wow-delay="100ms">
          <div className="counter-three__box @@extraClassName">
            <div className="counter-three__box__icon">
              <span className="icon-happy-Travel" />
            </div>
            {/* /.counter-box__icon */}
            <h3 className="counter-three__box__number count-box">
              <span className="count-text" data-stop="1K+" data-speed={1500} />
              1 K+
            </h3>
            <p className="counter-three__box__title">Happy Traveller</p>
          </div>
        </div>
        {/* /.col-xl-2 col-md-4 */}
        <div className="col-xl-2 col-md-4 wow fadeInUp" data-wow-delay="200ms">
          <div className="counter-three__box @@extraClassName">
            <div className="counter-three__box__icon">
              <span className="icon-tent-1" />
            </div>
            {/* /.counter-box__icon */}
            <h3 className="counter-three__box__number count-box">
              <span className="count-text" data-stop="50+" data-speed={1500} />
              50+
            </h3>
            <p className="counter-three__box__title">Eco-friendly</p>
          </div>
        </div>
        {/* /.col-xl-2 col-md-4 */}
        <div className="col-xl-2 col-md-4 wow fadeInUp" data-wow-delay="300ms">
          <div className="counter-three__box @@extraClassName">
            <div className="counter-three__box__icon">
              <span className="icon-satisfied" />
            </div>
            {/* /.counter-box__icon */}
            <h3 className="counter-three__box__number count-box">
              <span className="count-text" data-stop="4.9" data-speed={1500} />
              4.9
            </h3>
            <p className="counter-three__box__title">GMB Rating</p>
          </div>
        </div>
        {/* /.col-xl-2 col-md-4 */}
      </div>
      {/* /.row */}
    </div>
    {/* /.container */}
    <div className="counter-three__shape-bottom" />
  </section>

  {/* <section className="destination-three">
    <div className="container">
      <Places/>
    </div>
  </section> */}


  {/* Counter Three End */}
  

  {/* <section className="why-choose-three">
    <YchoseUs/>
  </section> */}
  {/* /.why-choose-three */}
  {/* blog-one Start */}
  
  {/* <Article/> */}

  {/* /.blog-one */}
  
  <Testmonial/>

  <Blog/>
  {/* CTA Two Start */}
  {/* <section className="cta-two">
    <div className="container">
      <div className="row">
        <div className="col-lg-9 wow fadeInUp" data-wow-delay="100ms">
          <h5 className="cta-two__sub-title">Book Your Trip Now</h5>
          <h3 className="cta-two__title">
            Are You Ready to Travel &amp; Enjoy
          </h3>
        </div>
        <div
          className="col-lg-3 cta-two__btn wow fadeInUp"
          data-wow-delay="200ms"
        >
          <a href="/" className="trevlo-btn">
            <span>Start Booking</span>
          </a>
        </div>
      </div>
    </div>
  </section> */}
  {/* CTA Two End */}

  {/* <Footer/> */}
  
  {/* /.main-footer */}
  {/* /.page-wrapper */}
  <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay mobile-nav__toggler" />
    {/* /.mobile-nav__overlay */}
    <div className="mobile-nav__content">
      <span className="mobile-nav__close mobile-nav__toggler">
        <i className="fa fa-times" />
      </span>
      <div className="logo-box">
        <a href="index.html" aria-label="logo image">
          <img src="assets/images/logo-dark.png" width={155} alt="" />
        </a>
      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
          <i className="fa fa-envelope" />
          <a href="mailto:needhelp@trevlo.com">needhelp@trevlo.com</a>
        </li>
        <li>
          <i className="fa fa-phone-alt" />
          <a href="tel:(303)5550105">(303) 555-0105</a>
        </li>
      </ul>
      {/* /.mobile-nav__contact */}
      <div className="mobile-nav__social">
        <a href="https://twitter.com/">
          <i className="fab fa-twitter" aria-hidden="true" />
          <span className="sr-only">Twitter</span>
        </a>
        <a href="https://facebook.com/">
          <i className="fab fa-facebook" aria-hidden="true" />
          <span className="sr-only">Facebook</span>
        </a>
        <a href="https://pinterest.com/">
          <i className="fab fa-pinterest-p" aria-hidden="true" />
          <span className="sr-only">Pinterest</span>
        </a>
        <a href="https://instagram.com/">
          <i className="fab fa-instagram" aria-hidden="true" />
          <span className="sr-only">Instagram</span>
        </a>
      </div>
      {/* /.mobile-nav__social */}
    </div>
    {/* /.mobile-nav__content */}
  </div>
  {/* /.mobile-nav__wrapper */}
  <div className="search-popup">
    <div className="search-popup__overlay search-toggler" />
    {/* /.search-popup__overlay */}
    <div className="search-popup__content">
      <form
        role="search"
        method="get"
        className="search-popup__form"
        action="#"
      >
        <input type="text" id="search" placeholder="Search Here..." />
        <button
          type="submit"
          aria-label="search submit"
          className="search-popup__btn trevlo-btn trevlo-btn--base"
        >
          <span className="icon-search" />
        </button>
      </form>
    </div>
    {/* /.search-popup__content */}
  </div>
  {/* /.search-popup */}
  <a href="/" data-target="html" className="scroll-to-target scroll-to-top">
    <span className="scroll-to-top__text">back top</span>
    <span className="scroll-to-top__wrapper">
      <span className="scroll-to-top__inner" />
    </span>
  </a>
  </div>
</>
  )
}

export default Home
